























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































  @import '~@/assets/styles/helpers/_variables.scss';

  .v-card { border-radius: 16px !important; }

  .optionsButton {
    color: white;
    padding: 6px;
    background-color: $colorAccent;
    border-radius: 16px;
    margin: 0 auto;
  }
  div.limit-row {
    max-height: 55px;
    @media(max-width: 768px) {
      max-height: initial;
    }
    .v-input::v-deep .v-input__control .v-input__slot {
        min-height: 45px;
    }
    .v-input::v-deep .v-input__append-inner{
      margin-top: 10px
    }
    .v-input::v-deep .v-input__append-outer{
      margin-top: 12px
    }
    .checkbox::v-deep .v-input__control .v-input__slot{
      margin-bottom: 0;
    }
  }
.checkbox::v-deep .v-input__control .v-input__slot{
  margin-bottom: 0;
}
.checkbox{
  max-height: 25px;
}

.files {
  margin-top: 5px;
  position: relative;
}

.files-icon {
  position: absolute;
  z-index: 10;
}

.files-add {
  position: relative;
  background: #0000000f;
  min-height: 40px;
  padding: 5px;
  width: 100%;
  margin-bottom: 15px;
  padding-left: 20px;
  position: relative;
  border: 1px solid #9e9e9e;
  border-radius: 4px;
  transition: all 0.3;
  &:hover {
    border: 1px solid #242424;
  }

  &-title {
    position: absolute;
    left: 50%;
    top: -80%;
    z-index: 0;
    transform: translate(-50%, 50%);
    font-size: 14px;
  }
  span {
    margin: 5px;
  }
}
